import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

export interface SearchbarProps extends RouteComponentProps {
    buttons?: {
        title: string,
        onClick: () => void
    }[],
    dropdown?: {
        dropdownItems: DropdownItemProps[],
        selected: string,
        onChange: (val: string) => void
    }

}

export default withRouter(class Searchbar extends React.Component<SearchbarProps, {
    dropdownVal?: string
}> {

    constructor(props: SearchbarProps) {
        super(props);
        this.state = {
            dropdownVal: props.dropdown && props.dropdown.selected
        }
    }

    render() {
        const { buttons, dropdown } = this.props
        return (
            <div className="create">
                <div className="row">
                    <div className={dropdown ? "col-md-6 col-12" : "col-12"}>
                        {buttons && buttons.map((button) => {
                            return (
                                <button onClick={() => button.onClick()}>{button.title}</button>
                            )
                        })}
                    </div>
                    {dropdown &&
                        <div className="col-md-6 col-12" style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Dropdown
                                    className="fitjo-dropdown"
                                    value={this.state.dropdownVal}
                                    fluid
                                    selection
                                    options={dropdown.dropdownItems}
                                    onChange={(_, { value }) => {
                                        this.setState({ dropdownVal: value as string })
                                        dropdown.onChange(value as string)
                                    }}
                                />
                        </div>
                    }
                </div>
            </div>
        )
    }
})
import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { API } from '../../api/endpoints/company';
import { CompanyEvent } from '../../api/models/companyEvent';

export const CompanyEventDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let companyEventId = query.get("companyEventId")
    return (
        <CompanyEventDetailInner key={companyEventId} companyEventId={companyEventId} />
    )
}

export interface CompanyEventDetailProps extends RouteComponentProps {
    companyEventId?: string | null
}

const CompanyEventDetailInner = withRouter(class extends React.Component<CompanyEventDetailProps, {
    companyEvent?: CompanyEvent
    loading: boolean
}> {

    constructor(props: CompanyEventDetailProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.props.companyEventId ? this.getCompanyEvent(this.props.companyEventId) : this.setState({ loading: false })
    }

    getCompanyEvent(companyEventId: string) {
        API.getCompanyEvent(companyEventId).then((event) => {
            this.setState({companyEvent: event})
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { companyEvent } = this.state
        return (
            <div className="main">
                {companyEvent ?
                    <div className="row">
                        <div className="col-xl-8 col-md-10 col-12">
                            <div className="challenge-detail-card">
                                <h3>{companyEvent.name}</h3>
                                {companyEvent.img_url &&
                                    <img className="lg" style={{marginRight: 0}} src={companyEvent.img_url} />
                                }
                                <div className="text">
                                    {companyEvent.description}
                                </div>
                                <div className="edit_delete">
                                    <Link to={`/edit-companyEvent?eventId=${companyEvent.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div>{"Das Event kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }

})

export default React.memo(CompanyEventDetail)
import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { UPLOAD_IMAGE } from '../../assets';
import { TRAINING_TYPE } from '../../utils/constants';
import 'semantic-ui-css/semantic.min.css'
import { QrAPI, PartnerAPI } from '../../api/endpoints/';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr';
import qrCode from 'qrcode';
import {v4 as uuidv4} from 'uuid';
import {QrCode} from "../../api/models/qrCode";
import {Partner} from "../../api/models/partner";
import { saveAs } from 'file-saver';

export const AddQr: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let partnerId = query.get("partnerId")
    return (
        <AddPartnerInner key={partnerId} partnerId={partnerId} />
    )
}

export interface AddPartnerProps extends RouteComponentProps {
    partnerId?: string | null
}

const AddPartnerInner = withRouter(class extends React.Component<AddPartnerProps, {
    name: string,
    description: string,
    points: string,
    loading: boolean,
    saveLoading: boolean,
    deleteLoading: boolean,
    url: string,
    partnercontact: string,
    mail: string,
    phone: string,
    qrTitle: string,
    qrCodes: QrCode[],
    addedQrCodes: QrCode[],
    deletedQrCodes: QrCode[]
}> {

    constructor(props: AddPartnerProps) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            description: "",
            saveLoading: false,
            deleteLoading: false,
            points: "",
            url: "",
            partnercontact: "",
            mail: "",
            phone: "",
            qrTitle: "",
            qrCodes: [],
            addedQrCodes: [],
            deletedQrCodes: []
        }
    }

    componentDidMount() {
        this.props.partnerId ? this.getPartnerWithQr(this.props.partnerId) : this.setState({ loading: false })
    }

    getPartnerWithQr(partnerId: string) {
        PartnerAPI.getPartner(partnerId).then(partner => {
            this.setState({ name: partner.company_name, url: partner.url, partnercontact: partner.partner, mail: partner.mail,
            phone: partner.phone})
        }).catch((err) => {
          toastr.error("Der Partner konnte nicht geladen werden.");
          this.goBack();
        }).finally(() => {
            this.setState({ loading: false })
        });

        QrAPI.getQr(partnerId).then(qr => {
            console.log(qr);
            qr.forEach(element => {
                this.state.qrCodes.push(element);
            });
            this.setState({ qrCodes: this.state.qrCodes })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ClipLoader/>
            </div>
        }
        return (
            <>
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.partnerId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Partner löschen"}
                                    onClick={() => {this.onDelete()}}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="workout_add_card">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Name</p>
                                        <input
                                            placeholder="Firmenname"
                                            value={this.state.name}
                                            onChange={(event) => {
                                                this.setState({name: event.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <p>URL</p>
                                        <input
                                            placeholder="https://www.url.de"
                                            value={this.state.url}
                                            onChange={(event) => {
                                                this.setState({url: event.target.value})
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Ansprechpartner</p>
                                        <input
                                            placeholder="Ansprechpartner"
                                            value={this.state.partnercontact}
                                            onChange={(event) => {
                                                this.setState({partnercontact: event.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 position-static">
                                        <p>QR Codes</p>
                                        { this.state.qrCodes.length > 0 && this.state.qrCodes.map((item, key) =>
                                            <div key={key}>Name: {item.name} - Punkte: {item.points}
                                            <i className={'fa fa-download'} style={{cursor: 'pointer', marginLeft: '10px'}} onClick={() => this.downloadQr(item.qr, item.name) }></i>
                                            <i className={'fa fa-trash'} style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={ () => this.deleteQr(key, item) }></i></div>) }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Kontakt</p>
                                        <input
                                            placeholder="Mail"
                                            value={this.state.mail}
                                            onChange={(event) => {
                                                this.setState({mail: event.target.value})
                                            }}
                                        />
                                        <input
                                            placeholder="Telefon"
                                            value={this.state.phone}
                                            onChange={(event) => {
                                                this.setState({phone: event.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>QR Code generieren</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <input
                                            placeholder="Titel"
                                            value={this.state.qrTitle}
                                            onChange={(event) => {
                                                this.setState({qrTitle: event.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            placeholder="Punkte"
                                            value={this.state.points}
                                            onChange={(event) => {
                                                this.setState({points: event.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-1">
                                        <Button
                                            text={"Generieren"}
                                            onClick={() => this.generateQr()}
                                            loading={this.state.saveLoading}
                                            disabled={this.state.saveLoading || this.state.deleteLoading}
                                            className={"primary"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    async onSave() {
        if (this.isValid()) {
            if (this.props.partnerId) {
                console.log("we need to update partner!");
                let partnerResult = await PartnerAPI.updatePartner(this.props.partnerId, this.state.partnercontact, this.state.url, this.state.mail,
                    this.state.phone, this.state.name);
                toastr.success("Partner erfolgreich geupdated!");

                let deleteQrResult = QrAPI.deleteQr(this.state.deletedQrCodes);
                this.state.deletedQrCodes.length = 0;

                this.state.addedQrCodes.forEach(element => {
                    element.partner_id = this.props.partnerId!;
                })
                let updateQrResult = QrAPI.uploadQr(this.state.addedQrCodes);
                this.state.addedQrCodes.length = 0;
            }
            else {
                console.log(this.state.qrCodes);
                let partnerResult = await PartnerAPI.savePartner(this.state.partnercontact, this.state.url, this.state.mail,
                    this.state.phone, this.state.name);
                console.log(partnerResult);
                toastr.success("Partner erfolgreich gespeichert!");
                this.state.qrCodes.forEach(element => {
                    console.log("RES");
                    console.log(partnerResult);
                    element.partner_id = partnerResult.id;
                })
                let qrResult = QrAPI.uploadQr(this.state.qrCodes);
                toastr.success("QR codes erfolgreich gespeichert!");
                console.log(qrResult);
            }
        }
    }

    onDelete() {
        PartnerAPI.deletePartner(this.props.partnerId!);
        this.props.history.goBack();
        toastr.warning("Partner gelöscht!");
    }

    generateQr() {
        if (this.isValidQr()) {
            this.generateQRCodeWithUUID("").then(r => {
                let newQr = new QrCode();
                if (r !== undefined) {
                    newQr.qr = r.uuid;

                    console.log(r.url);
                }

                newQr.points = parseInt(this.state.points);
                newQr.name = this.state.qrTitle;


                this.state.qrCodes.push(newQr);
                this.setState({ qrCodes: this.state.qrCodes });

                if(this.props.partnerId) {
                    this.state.addedQrCodes.push(newQr);
                }
            });
        }
    }

    isValid() {
        let valid = true
        if(!this.state.name) {
            toastr.warning("Bitte gebe einen Firmennamen an")
            valid = false
        }
        return valid
    }

    isValidQr() {
        let valid = true;
        if(!this.state.qrTitle) {
            toastr.warning("Bitte gebe einen gültigen QR Titel an")
            valid = false
        }
        if(!this.state.points) {
            toastr.warning("Bitte gebe eine Punktzahl an")
            valid = false
        }
        if(this.state.points && isNaN(Number(this.state.points))) {
            toastr.warning("Bitte gebe eine gültige Punktzahl an")
            valid = false
        }
        return valid;
    }

    async generateQRCodeWithUUID(generatedUuid: string) {
        try {
            let uuid = "";
            if (generatedUuid === ""){
                uuid = uuidv4();
            }
            else {
                uuid = generatedUuid;
            }

            const qrCodeDataUrl = qrCode.toDataURL(uuid);
            // qrCodeDataUrl now contains the generated QR code as a data URL
            return { uuid: uuid, url: qrCodeDataUrl };
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    }

    deleteQr(key: number, qrCode: QrCode) {
        this.state.qrCodes.splice(key,1);
        this.setState({ qrCodes: this.state.qrCodes });
        if (this.props.partnerId) {
            this.state.deletedQrCodes.push(qrCode);
        }
    }

    goBack() {
        this.props.history.push("/add-qr")
    }

    async downloadQr(uuid: string, name: string) {
        let link = await this.generateQRCodeWithUUID(uuid);
        const url = await link!.url;

        const response = await fetch(url);
        const blob = await response.blob();
        saveAs(blob, name+'-qr-code.png');
    }

})

export default React.memo(AddQr)
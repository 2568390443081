import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { ADD_ICON, ARROW_ICON, REMOVE_ICON, UPLOAD_IMAGE } from '../../assets';
import { RECIPE_DROPDOWN_ITEMS, RECIPE_TYPE, TRAINING_DROPDOWN_ITEMS } from '../../utils/constants';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { API } from '../../api/endpoints/recipes';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'
import { RecipeType } from '../../api/models/recipe';
import { RecipeIngredient } from '../../api/models/recipeIngredient';
import { RecipeStep } from '../../api/models/recipeStep';

export const AddRecipe: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let recipeId = query.get("recipeId")
    return (
        <AddRecipeInner key={recipeId} recipeId={recipeId} />
    )
}

export interface AddRecipeProps extends RouteComponentProps {
    recipeId?: string | null
}

const AddRecipeInner = withRouter(class extends React.Component<AddRecipeProps, {
    title: string,
    abstract: string,
    description: string,
    type: RecipeType,
    ingredients: RecipeIngredient[],
    steps: string[],
    img_file?: File | null
    img_url?: string
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
    ingredientAmount: string,
    ingredientUnit: string,
    ingredientName: string
    stepDescription: string
}> {

    constructor(props: AddRecipeProps) {
        super(props);
        this.state = {
            type: RECIPE_TYPE.BREAKFAST as RecipeType,
            loading: true,
            title: "",
            description: "",
            ingredients: [],
            steps: [],
            saveLoading: false,
            deleteLoading: false,
            abstract: "",
            ingredientAmount: "",
            ingredientName: "",
            ingredientUnit: "",
            stepDescription: ""
        }
        this.selectImage = this.selectImage.bind(this)
    }
    private imgInput: HTMLInputElement | null = null
    private ingredientAmountInput: HTMLInputElement | null = null
    private ingredientUnitInput: HTMLInputElement | null = null
    private ingredientNameInput: HTMLInputElement | null = null
    private stepDescriptionInput: HTMLTextAreaElement | null = null

    componentDidMount() {
        this.props.recipeId ? this.getRecipe(this.props.recipeId) : this.setState({ loading: false })
    }

    getRecipe(recipeId: string) {
        API.getRecipe(recipeId).then((recipe) => {
            this.setState({
                title: recipe.name,
                abstract: recipe.abstract || "",
                description: recipe.description,
                type: recipe.type,
                ingredients: recipe.ingredients,
                steps: recipe.steps.sort((a, b) => a.step < b.step ? -1 : a.step > b.step ? 1 : 0).map((step) => step.description),
                img_url: recipe.img_url
            })
        }).catch((err) => {
            toastr.error("Das Rezept konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.imgInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.recipeId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Rezept löschen"}
                                    onClick={() => { this.deleteRecipe() }}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <span className="info">{"Mit * markierte sind optionale Felder"}</span>
                                <div className="row">

                                    <div className="col-sm-6">
                                        <p>Titel</p>
                                        <input
                                            placeholder="Rezept Titel"
                                            value={this.state.title}
                                            onChange={(event) => {
                                                this.setState({ title: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Kategorie</p>
                                        <Dropdown
                                            value={this.state.type}
                                            fluid
                                            selection
                                            options={RECIPE_DROPDOWN_ITEMS}
                                            onChange={(_, { value }) => {
                                                this.setState({ type: value as RecipeType })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Kurzbeschreibung*</p>
                                        <textarea
                                            rows={4}
                                            placeholder="Kurzbeschreibung"
                                            value={this.state.abstract}
                                            onChange={(event) => {
                                                this.setState({ abstract: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Beschreibung</p>
                                        <textarea
                                            rows={4}
                                            placeholder="Beschreibung"
                                            value={this.state.description}
                                            onChange={(event) => {
                                                this.setState({ description: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Titelbild hinzufügen*</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.imgInput && this.imgInput.click()
                                        }} />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                    <div className="col-md-6" />

                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-3 col-2">
                                                <p>Menge*</p>
                                            </div>
                                            <div className="col-md-3 col-2">
                                                <p>Einheit*</p>
                                            </div>
                                            <div className="col-md-4 col-5">
                                                <p>Zutat</p>
                                            </div>
                                            <div className="col-md-2 col-3">
                                            </div>
                                        </div>
                                        {this.renderIngredients()}
                                        <div className="row">
                                            <div className="col-md-3 col-2">
                                                <input
                                                    ref={(ref) => this.ingredientAmountInput = ref}
                                                    className="sm"
                                                    placeholder=""
                                                    value={this.state.ingredientAmount}
                                                    onChange={(event) => {
                                                        this.setState({ ingredientAmount: event.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 col-2">
                                                <input
                                                    ref={(ref) => this.ingredientUnitInput = ref}
                                                    className="sm"
                                                    placeholder=""
                                                    value={this.state.ingredientUnit}
                                                    onChange={(event) => {
                                                        this.setState({ ingredientUnit: event.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-4 col-5">
                                                <input
                                                    ref={(ref) => this.ingredientNameInput = ref}
                                                    className="sm"
                                                    placeholder="Zutat"
                                                    value={this.state.ingredientName}
                                                    onChange={(event) => {
                                                        this.setState({ ingredientName: event.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-2 col-3">
                                                <div className="icon-container lg">
                                                    <img src={ADD_ICON} onClick={() => {
                                                        this.addIngredient()
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-2">
                                                <p>Schritt</p>
                                            </div>
                                            <div className="col-7">
                                                <p>Beschreibung</p>
                                            </div>
                                            <div className="col-3">
                                            </div>
                                        </div>
                                        {this.renderSteps()}
                                        <div className="row">
                                            <div className="col-2" />
                                            <div className="col-7">
                                                <textarea
                                                    ref={(ref) => this.stepDescriptionInput = ref}
                                                    className="sm"
                                                    rows={2}
                                                    placeholder="Beschreibung des Schrittes"
                                                    value={this.state.stepDescription}
                                                    onChange={(event) => {
                                                        this.setState({ stepDescription: event.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <div className="icon-container lg">
                                                    <img src={ADD_ICON} onClick={() => {
                                                        this.addStep()
                                                    }} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ img_file: file, img_url: url })
            }
        }
    }

    renderIngredients() {
        return this.state.ingredients.map((ingredient, index) => {
            return (
                <div className="row" style={{ padding: "3px 0px" }} key={`ingredient_${index}`}>
                    <div className="col-md-3 col-2">
                        <span className="list">{ingredient.amount}</span>
                    </div>
                    <div className="col-md-3 col-2">
                        <span className="list">{ingredient.unit}</span>
                    </div>
                    <div className="col-md-4 col-5">
                        <span className="list">{ingredient.name}</span>
                    </div>
                    <div className="col-md-2 col-3">
                        <div className="icon-container sm">
                            <img src={REMOVE_ICON} onClick={() => {
                                let ingredients = this.state.ingredients.slice()
                                ingredients.splice(index, 1)
                                this.setState({ ingredients })
                            }} />
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderSteps() {
        return this.state.steps.map((step, index) => {
            return (
                <div className="row" style={{ padding: "3px 0px" }} key={`step_${index}`}>
                    <div className="col-2">
                        <span className="list">{`${index + 1}.`}</span>
                    </div>
                    <div className="col-7">
                        <span className="list">{step}</span>
                    </div>
                    <div className="col-3">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="icon-container sm">
                                <img src={REMOVE_ICON} onClick={() => {
                                    let steps = this.state.steps.slice()
                                    steps.splice(index, 1)
                                    this.setState({ steps })
                                }} />
                            </div>
                            <div className="icon-container sm">
                                <img src={ARROW_ICON} onClick={() => {
                                    if (index > 0) {
                                        let steps = this.state.steps.slice()
                                        steps[index] = steps.splice(index - 1, 1, steps[index])[0];
                                        this.setState({ steps })
                                    }
                                }} />
                            </div>
                            <div className="icon-container sm">
                                <img src={ARROW_ICON} style={{ transform: "rotate(180deg)" }} onClick={() => {
                                    let steps = this.state.steps.slice()
                                    if (index < steps.length - 1) {
                                        steps[index] = steps.splice(index + 1, 1, steps[index])[0];
                                        this.setState({ steps })
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    addIngredient() {
        if (this.state.ingredientName) {
            if (this.state.ingredientAmount && isNaN(Number(this.state.ingredientAmount))) {
                toastr.warning("Bitte gebe eine gültige Menge an")
                return
            }
            let ingredients = this.state.ingredients.slice()
            ingredients.push({ amount: this.state.ingredientAmount ? Number(this.state.ingredientAmount) : undefined, unit: this.state.ingredientUnit, name: this.state.ingredientName })
            this.setState({ ingredients, ingredientAmount: "", ingredientUnit: "", ingredientName: "" })
            this.ingredientAmountInput && (this.ingredientAmountInput.value = "")
            this.ingredientUnitInput && (this.ingredientUnitInput.value = "")
            this.ingredientNameInput && (this.ingredientNameInput.value = "")

        } else {
            toastr.warning("Bitte gebe eine Zutat an")
        }
    }

    addStep() {
        if (this.state.stepDescription) {
            let steps = this.state.steps.slice()
            steps.push(this.state.stepDescription)
            this.setState({ steps, stepDescription: "" })
            this.stepDescriptionInput && (this.stepDescriptionInput.value = "")
        } else {
            toastr.warning("Bitte gebe eine Beschreibung des Schrittes an")
        }
    }



    onSave() {
        if (this.state.title && this.state.description) {
            this.setState({ saveLoading: true })
            if (this.state.img_file) {
                API.uploadRecipeImage(this.state.img_file).then((res) => {
                    this.addRecipe(this.state.title, this.state.description, this.state.type, this.state.abstract, res)
                }).catch(() => {
                    toastr.warning("Bild konnte nicht hochgeladen werden")
                    this.addRecipe(this.state.title, this.state.description, this.state.type, this.state.abstract)
                })
            } else {
                this.addRecipe(this.state.title, this.state.description, this.state.type, this.state.abstract)
            }
        } else {
            if (!this.state.title) {
                toastr.warning("Bitte gebe einen Titel an")
            }
            if (!this.state.description) {
                toastr.warning("Bitte gebe eine Beschreibung an")
            }
        }
    }

    addRecipe(name?: string, description?: string, type?: RecipeType, abstract?: string, img_url?: string) {
        if (this.props.recipeId) {
            API.updateRecipe(this.props.recipeId, name, description, type, abstract, img_url).then((recipe) => {
                this.addIngredientsAndSteps(recipe.id!)
            }).catch((err) => {
                toastr.error("Rezept konnte nicht gespeichert werden")
                this.setState({ saveLoading: false })
            })
        } else {
            API.addRecipe(name!, description!, type!, abstract, img_url).then((recipe) => {
                this.addIngredientsAndSteps(recipe.id!)
            }).catch((err) => {
                toastr.error("Rezept konnte nicht gespeichert werden")
                this.setState({ saveLoading: false })
            })
        }
    }

    addIngredientsAndSteps(recipeId: string) {
        let steps: RecipeStep[] = this.state.steps.map((val, index) => { return ({ step: index, description: val }) })
        Promise.all([API.addRecipeIngredients(recipeId, this.state.ingredients), API.addRecipeSteps(recipeId, steps)]).then(([ingredientRes, stepRes]) => {
            toastr.success("Rezept wurde gespeichert")
        }).catch((err) => {
            toastr.error("Rezept konnte nicht richtig gespeichert werden")
        }).finally(() => {
            this.setState({ saveLoading: false })
            this.goBack()
        })
    }

    deleteRecipe() {
        this.setState({ deleteLoading: true })
        this.props.recipeId && API.deleteRecipe(this.props.recipeId).then(() => {
            toastr.success("Rezept wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Rezept konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({ deleteLoading: false })
        })
    }

    goBack() {
        this.props.history.push("/recipes")
    }

})

export default React.memo(AddRecipe)
import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";

export type EventType = 'training_business' | 'training_home' | 'meditation'

export class CalendarEvent extends BaseModel {

    @JsonProperty({ type: 'Date' })
    start_date: Date

    @JsonProperty({ type: 'Date' })
    end_date: Date

    @JsonProperty({ type: 'number?' })
    reminder?: number[];

    @JsonProperty({ type: 'string' })
    type: EventType;
}
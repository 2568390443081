import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar';
import { UserContextType } from '../../context/user';
import { CONTACT_ICON, ICON_KEY, MAIL_ICON, PHONE_ICON, WEBSITE_ICON } from '../../assets';
import { Company as ICompany } from '../../api/models/company';
import { CompanyAPI, UserAPI } from '../../api/endpoints';
import ClipLoader from 'react-spinners/ClipLoader';
import { CountInviteKeys } from '../../api/endpoints/company';

export interface CompanyProps extends RouteComponentProps {
}

export default withRouter(class Company extends React.Component<CompanyProps, {
    company?: ICompany,
    inviteKeys?: CountInviteKeys
    loading: boolean
}> {

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>


    constructor(props: CompanyProps) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.getCompany()
    }

    getCompany() {
        Promise.all([UserAPI.getCompany(), CompanyAPI.countOwnInviteKeys()]).then(([company, inviteKeys]) => {
            this.setState({ company, inviteKeys })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { company, inviteKeys } = this.state
        return (
            <>
                <Searchbar
                    buttons={[
                        {
                            title: "Unternehmen ändern",
                            onClick: () => { this.props.history.push("/change-company") }
                        }
                    ]}
                />
                <div className="main">
                    {company ?
                        <div className="row">
                            <div className="col-12">
                                <div className="challenge-detail-card">
                                    <h3>{company.name}</h3>
                                    <div className="custom-row">
                                        {company.img_url && <img src={company.img_url} />}
                                        <div>
                                            <div className="info"><img src={CONTACT_ICON} />{company.contact}</div>
                                            <div className="info"><img src={MAIL_ICON} />{company.email}</div>
                                            <div className="info"><img src={WEBSITE_ICON} />{company.website}</div>
                                            <div className="info"><img src={PHONE_ICON} />{company.phone_number}</div>
                                            <div className="info"><img src={ICON_KEY} />{inviteKeys ? `${inviteKeys.inUse} / ${inviteKeys.total}` : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div>{"Das Unternehmen kann gerade nicht geladen werden."}</div>
                    }



                </div>
            </>
        )
    }
})
import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { API } from '../../api/endpoints/recipes';
import { Recipe, RecipeType } from '../../api/models/recipe';
import Searchbar from '../../components/Searchbar';
import { RECIPE_DROPDOWN_ITEMS, RECIPE_TYPE } from '../../utils/constants';
import ClipLoader from 'react-spinners/ClipLoader';

export interface RecipesProps extends RouteComponentProps {

}

export default withRouter(class Recipes extends React.Component<RecipesProps, {
    loading: boolean
    recipes?: Recipe[],
    page: number,
    count?: number,
    type: RecipeType
}> {

    constructor(props: RecipesProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            type: 'breakfast'
        }
    }

    private limit: number = 12

    componentDidMount() {
        this.countRecipes()
        this.getRecipes()
    }

    getRecipes() {
        API.getRecipes(this.state.type, this.limit, this.limit * (this.state.page - 1)).then((recipes) => {
            this.setState({ recipes, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countRecipes() {
        API.countRecipes(this.state.type).then((count) => {
            this.setState({count})
        }).catch((err) => {})
    }

    render() {

        return (
            <div>
                <Searchbar
                    buttons={[
                        {
                            title: "Rezept anlegen",
                            onClick: () => { this.props.history.push("/add-recipe") }
                        }
                    ]}
                    dropdown={{
                        dropdownItems: RECIPE_DROPDOWN_ITEMS,
                        selected: RECIPE_TYPE.BREAKFAST,
                        onChange: (val) => {
                            this.setState({ loading: true, type: val as RecipeType }, () => {
                                this.getRecipes()
                            })
                        }
                    }}
                />
                <div className="main">
                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.recipes ?
                            this.state.recipes.length > 0 ?
                                <div className="row">
                                    {this.state.recipes.map((recipe) => {
                                        return this.renderRecipe(recipe)
                                    })}
                                </div>
                                :
                                <div>No Recipes yet</div>
                            :
                            <div>Cannot load Recipes</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.recipes) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </div>
        )
    }

    renderRecipe(recipe: Recipe) {
        return (
            <div className="col-lg-3 col-md-4 col-6" key={`recipe_${recipe.id}`}>
                <div className="rezeptcard">
                    <h6>{recipe.name}</h6>
                    <img src={recipe.img_url} />
                    <div className="text">
                        {/*                         <p>Natalie M. am 29.07.2021</p> */}
                        <p>{recipe.description}</p>
                    </div>
                    <div className="edit_delete">
                        <Link to={`/recipe-detail?recipeId=${recipe.id}`}>
                            <i className="fa fa-info-circle"></i>
                        </Link>
                        <Link to={`/edit-recipe?recipeId=${recipe.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getRecipes()
        })
    }
})
import * as React from 'react'
import {withRouter, RouteComponentProps, Link} from 'react-router-dom';
import Searchbar from "../../components/Searchbar";
import {QrAPI} from "../../api/endpoints";
import {PartnerAPI} from "../../api/endpoints";
import ClipLoader from "react-spinners/ClipLoader";
import {QrCode} from "../../api/models/qrCode";
import {Partner} from "../../api/models/partner";

export interface QrProps extends RouteComponentProps {

}

export default withRouter(class Qr extends React.Component<QrProps, {
    loading: boolean,
    page: number,
    partners?: Partner[]
}> {

    constructor(props: QrProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1
        }
    }

    componentDidMount() {
        this.getPartners();
    }

    getPartners() {
        PartnerAPI.getAllPartners().then(partner => {
            this.setState({partners: partner, loading: false});
        }).catch(err => {
            this.setState({loading: false});
        })
    }

    render () {
        return (
          <>
              <Searchbar
                  buttons={[
                      {
                          title: "Partner erstellen",
                          onClick: () => { this.props.history.push("/add-qr") }
                      }
                  ]}
              />
              <div className="main">
                  {this.state.loading ?
                      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                          <ClipLoader />
                      </div>
                      :
                      this.state.partners ?
                          this.state.partners.length > 0 ?
                              <div className="row">
                                  {this.state.partners.map((partner) => {
                                      return this.renderPartner(partner);
                                  })}
                              </div>
                              :
                              <div>No Partners yet</div>
                          :
                          <div>Cannot load Partners</div>
                  }
              </div>

          </>
        );
    }

    renderPartner(partner: Partner) {
        return (
            <div className="col-lg-12" key={`partner_${partner.id}`}>
                <div className="workoutcard">
                    <div className="row">
                        <div className="col-lg-5 col-8 box-align-center">
                            <span className="headline">{partner.company_name}</span>
                        </div>
                        <div className="col-lg-5 col-8 box-align-center">
                            <span className="headline">{partner.partner}</span>
                        </div>
                        <div className="col-lg-5 col-8 box-align-center">
                            <span className="headline">{partner.mail}</span>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="edit_delete">
                                <Link to={`/edit-partner?partnerId=${partner.id}`}>
                                    <i className="fa fa-edit"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

})
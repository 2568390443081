import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";

export type NewsType = 'general' | 'office' | 'training' | 'nutrition'

export class News extends BaseModel {

    @JsonProperty({ type: 'string' })
    headline: string;

    @JsonProperty({ type: 'string' })
    text: string;

    @JsonProperty({ type: 'string?' })
    img_url?: string;

    @JsonProperty({ type: 'string' })
    type: NewsType;
}
import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UserContextType } from '../../context/user';
import Button from '../../components/Button';
import { UPLOAD_IMAGE } from '../../assets';
import ClipLoader from 'react-spinners/ClipLoader';
import { validateEmail } from '../../utils/helper';
import { CompanyAPI, UserAPI } from '../../api/endpoints';
import { UserSession } from '../../utils/session';
import toastr from 'toastr'

export interface ChangeCompanyProps extends RouteComponentProps {

}

export default withRouter(class ChangeCompany extends React.Component<ChangeCompanyProps, {
    file?: File | null,
    img_url?: string,
    loading: boolean
    saveLoading: boolean,
    contact: string
    phone_number: string
    website: string,
    email: string,
}> {

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>


    constructor(props: ChangeCompanyProps) {
        super(props);
        this.state = {
            saveLoading: false,
            email: "",
            contact: "",
            phone_number: "",
            website: "",
            loading: true
        }
        this.selectImage = this.selectImage.bind(this)
    }

    private fileInput: HTMLInputElement | null = null

    componentDidMount() {
        this.getCompany()
    }

    getCompany() {
        UserAPI.getCompany().then((company) => {
            this.setState({ 
                email: company.email,
                contact: company.contact,
                phone_number: company.phone_number,
                website: company.website,
                img_url: company.img_url,
                loading: false
            })
        }).catch((err) => {

        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.fileInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading}
                                className={"primary"}
                            />
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <p>Kontaktperson</p>
                                        <input
                                            placeholder="Kontaktperson"
                                            value={this.state.contact}
                                            onChange={(event) => {
                                                this.setState({ contact: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Email</p>
                                        <input
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(event) => {
                                                this.setState({ email: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Telefonnummer</p>
                                        <input
                                            placeholder="Telefonnummer"
                                            value={this.state.phone_number}
                                            onChange={(event) => {
                                                this.setState({ phone_number: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Webseite</p>
                                        <input
                                            placeholder="Webseite"
                                            value={this.state.website}
                                            onChange={(event) => {
                                                this.setState({ website: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Profilbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.fileInput && this.fileInput.click()
                                        }} />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ file, img_url: url })
            }
        }
    }

    onSave() {
        if(this.isValid()) {
            this.setState({ saveLoading: true })
            if (this.state.file) {
                CompanyAPI.uploadCompanyImage(this.state.file).then((url) => {
                    this.updateCompany(url)
                }).catch((err) => {
                    toastr.success("Bild konnte nicht hochgeladen werden")
                    this.updateCompany()
                })
            } else {
                this.updateCompany()
            }
        }
    }

    updateCompany(img_url?: string) {
        UserAPI.updateCompany(this.state.contact, this.state.email, this.state.phone_number, this.state.website, img_url).then((company) => {
            toastr.success("Unternehmen wurde gespeichert")
            this.props.history.push("/company")
        }).catch((err) => {
            toastr.error("Profil konnte nicht gespeichert werden")
        }).finally(() => {
            this.setState({ saveLoading: false })
        })
    }

    isValid() {
        let valid = true
        if(!validateEmail(this.state.email)) {
            toastr.warning("Bitte gebe eine gültige Email Adresse an")
            valid = false
        }
        return valid
    }

})
import { Challenge } from "../models/challenge";
import { Company } from "../models/company";
import { CompanyEvent } from "../models/companyEvent";
import { Device } from "../models/device";
import { InviteKey } from "../models/inviteKey";
import { APIVersion, AuthenticatedFileRequest, AuthenticatedRequest, HTTPMethod } from "../requests/request";
import { DeviceData } from "./device";

/**
 * Defines the payload for email registration
 */
type EmailRegistration = {
    email: string,
    password: string
}

type CompanyRegistration = {
    name: string,
    phone_number: string,
    //invite_key: string,
    website: string,
    img_url?: string
}

/**
 * Defines the payload for company registration
 */
interface RegistrationBodyParameters {
    registration_data: EmailRegistration;
    registration_fullname: string;
    //device: DeviceData,
    company: CompanyRegistration,
    fixedChallenges: Challenge[],
    invite_keys: number
}


export class API {

    /**
     * Upload a company image
     * 
     * @param image 
     */
    public static uploadCompanyImage(image: any): Promise<string> {
        let apiReqest = new AuthenticatedFileRequest<string>('/companies/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Create a new Company
     * 
     */
    public static addCompany(data: RegistrationBodyParameters): Promise<Company> {
        let apiReqest = new AuthenticatedRequest<Company>('/companies', HTTPMethod.POST, APIVersion.v1_0, undefined, data);
        return apiReqest.send(Company).then((response) => {
            return <Company>response.data
        })
    }

    /**
     * Update a Company
     * 
     * @param name 
     * @param contact 
     * @param email 
     * @param phone_number 
     * @param invite_key 
     * @param img_url 
     */
    public static updateCompany(id: string, name?: string, contact?: string, email?: string, phone_number?: string, website?: string, img_url?: string, invite_keys?: number, live_eligible?: string): Promise<Company> {
        let apiReqest = new AuthenticatedRequest<Company>(`/companies/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            name,
            contact,
            email,
            phone_number,
            img_url,
            website,
            invite_keys,
            live_eligible
        });
        return apiReqest.send(Company).then((response) => {
            return <Company>response.data
        })
    }

    /**
     * Returns all companies
     */
    public static getCompanies(limit?: number, offset?: number): Promise<Company[]> {
        let apiReqest = new AuthenticatedRequest<Company>('/companies', HTTPMethod.GET, APIVersion.v1_0, {
            limit,
            offset
        });
        return apiReqest.send(Company).then((response) => {
            return <Company[]>response.data
        })
    }

    /**
     * Returns a company
     */
    public static getCompany(id: string): Promise<Company> {
        let apiReqest = new AuthenticatedRequest<Company>(`/companies/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(Company).then((response) => {
            return <Company>response.data
        })
    }

    /**
     * Deletes a Company
     * 
     * @param id 
     */
    public static deleteCompany(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/companies/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return <{}>response.data
        })
    }


    /**
     * Count Companies
     */
    public static countCompanies(): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/companies/count', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }



    /** Invite Keys */


    /**
     * Count the InviteKeys of a company
     * 
     * @param id 
     */
    /*     public static countInviteKeys(id: string): Promise<CountInviteKeys> {
            let apiReqest = new AuthenticatedRequest<CountInviteKeys>(`/companies/${id}/inviteKeys`, HTTPMethod.GET, APIVersion.v1_0);
            return apiReqest.send().then((response) => {
                return response.data
            })
        } */

    /**
     * Count the InviteKeys of a company
     * 
     * @param id 
     */
    public static getInviteKeys(id: string): Promise<InviteKey[]> {
        let apiReqest = new AuthenticatedRequest<InviteKey[]>(`/companies/${id}/inviteKeys`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Count the InviteKeys of the own company
     * 
     * @param id 
     */
    public static countOwnInviteKeys(): Promise<CountInviteKeys> {
        let apiReqest = new AuthenticatedRequest<CountInviteKeys>(`/companies/inviteKeys`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Create InviteKeys for a company
     * 
     * @param id 
     */
    public static createInviteKeys(id: string, count?: number): Promise<InviteKey[]> {
        let apiReqest = new AuthenticatedRequest<InviteKey[]>(`/companies/${id}/createInviteKeys`, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            count
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Activates or deactives an InviteKey
     * 
     * @param id 
     */
    public static refreshInviteKey(id: string, active: boolean): Promise<InviteKey> {
        let apiReqest = new AuthenticatedRequest<InviteKey>(`/companies/refreshInviteKey/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            active
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Deletes an InviteKey
     * 
     * @param id 
     */
    public static deleteInviteKey(id: string): Promise<string> {
        let apiReqest = new AuthenticatedRequest<string>(`/companies/deleteInviteKey/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }



    /** Company Events */


    /**
     * Returns the Events of a company
     */
    public static getCompanyEvents(): Promise<CompanyEvent[]> {
        let apiReqest = new AuthenticatedRequest<CompanyEvent>('/companies/events', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(CompanyEvent).then((response) => {
            return <CompanyEvent[]>response.data
        })
    }

    /**
     * Upload a company event image
     * 
     * @param image 
     */
    public static uploadCompanyEventImage(image: any): Promise<string> {
        let apiReqest = new AuthenticatedFileRequest<string>('/companies/events/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * 
     * Create an event for a company
     * 
     * @param name 
     * @param description 
     * @param url 
     * @param img_url 
     */
    public static createCompanyEvent(name: string, description: string, url, img_url?: string): Promise<CompanyEvent> {
        let apiReqest = new AuthenticatedRequest<CompanyEvent>(`/companies/events`, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            description,
            url,
            img_url
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Upadte an event for a company
     * 
     * @param id 
     */
    public static updateCompanyEvent(id: string, name: string, description: string, url, img_url?: string): Promise<CompanyEvent> {
        let apiReqest = new AuthenticatedRequest<CompanyEvent>(`/companies/events/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            name,
            description,
            url,
            img_url
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Returns a company Event
     */
    public static getCompanyEvent(id: string): Promise<CompanyEvent> {
        let apiReqest = new AuthenticatedRequest<CompanyEvent>(`/companies/events/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(CompanyEvent).then((response) => {
            return <CompanyEvent>response.data
        })
    }

    /**
     * Deletes a Company Event
     * 
     * @param id 
     */
    public static deleteCompanyEvent(id: string): Promise<string> {
        let apiReqest = new AuthenticatedRequest<string>(`/companies/events/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

}

export interface CountInviteKeys {
    total: number,
    inUse: number,
    inactive: number
}
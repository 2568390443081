import { Discount } from "../models/discount";
import { APIVersion, AuthenticatedFileRequest, AuthenticatedRequest, HTTPMethod } from "../requests/request"

export class API {

    /**
     * Get the discounts
     */
    public static getDiscounts(limit?: number, offset?: number): Promise<Discount[]> {
        let apiReqest = new AuthenticatedRequest<Discount>('/discounts', HTTPMethod.GET, APIVersion.v1_0, {
            limit,
            offset
        });
        return apiReqest.send(Discount).then((response) => {
            return <Discount[]>response.data
        })
    }

    /**
     * Get a discount
     */
    public static getDiscount(id: string): Promise<Discount> {
        let apiReqest = new AuthenticatedRequest<Discount>(`/discounts/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(Discount).then((response) => {
            return <Discount>response.data
        })
    }

    /**
     * Upload a Picture
     * 
     * @param image Image file
     */
    public static uploadDiscountImage(image: any): Promise<string> {
        let apiReqest = new AuthenticatedFileRequest<string>('/discounts/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Add a discount
     * 
     * @param name 
     * @param description 
     * @param url 
     * @param code 
     * @param img_url 
     */
    public static addDiscount(name: string, description: string, url: string, code: string, img_url?: string): Promise<Discount> {
        let apiReqest = new AuthenticatedRequest<Discount>('/discounts', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            description,
            url,
            code,
            img_url
        });
        return apiReqest.send(Discount).then((response) => {
            return <Discount>response.data
        })
    }

    /**
     * Update a discount
     * 
     * @param name 
     * @param description 
     * @param url 
     * @param code 
     * @param img_url 
     */
    public static updateDiscount(id: string, name?: string, description?: string, url?: string, code?: string, img_url?: string): Promise<Discount> {
        let apiReqest = new AuthenticatedRequest<Discount>(`/discounts/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            name,
            description,
            url,
            code,
            img_url
        });
        return apiReqest.send(Discount).then((response) => {
            return <Discount>response.data
        })
    }

    /**
     * Deletes a Discount
     * 
     * @param id 
     */
    public static deleteDiscount(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/discounts/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return <{}>response.data
        })
    }

    /**
     * Count Discounts
     */
    public static countDiscounts(): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/discounts/count', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

}
import { Device } from '../models/device';
import { User } from '../models/user';
import { APIVersion, AuthenticatedRequest, BasicRequest, HTTPMethod } from '../requests/request';
import { DeviceData } from './device';
export class API {

    /**
     * Login
     * 
     * @param email email
     * @param password password
     * @param device Device Data
     */
    public static login(email: string, password: string, device: DeviceData): Promise<Device> {
        let apiReqest = new BasicRequest<Device>('/login', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            email,
            password,
            device,
            platform: 'dashboard'
        });
        return apiReqest.send(Device).then((response) => {
            return response.data
        })
    }

    /**
     * Register a new user
     * 
     * @param inviteKey 
     * @param email 
     * @param password 
     * @param name 
     * @param device 
     */
    public static register(inviteKey: string, email: string, password: string, name: string): Promise<User> {
        let apiReqest = new BasicRequest<User>('/users', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            registration_data: {
                email,
                password,
            },
            registration_fullname: name,
            invite_key: inviteKey
        });
        return apiReqest.send(User).then((response) => {
            return response.data
        })
    }

    /**
     * Change own password
     * 
     * @param old_password 
     * @param password 
     */
    public static changePassword(old_password: string, password: string): Promise<{}> {
        let apiRequest = new AuthenticatedRequest<{}>('/changepassword', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            old_password,
            password
        })
        return apiRequest.send().then((response) => {
            return response.data
        })
    }

    public static forgotPassword(email: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest<{}>('/forgotpassword', HTTPMethod.POST, APIVersion.v1_0, {}, { email })
        return apiRequest.send().then((response) => {
            return response.data
        })
    }
}
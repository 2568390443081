import * as React from 'react'
import {Link, RouteComponentProps, useLocation, withRouter} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import {Challenge, Ranking} from '../../api/models/challenge';
import {ChallengesAPI} from '../../api/endpoints';

export const ChallengeDetail: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let challengeId = query.get("challengeId")
    return (
        <ChallengeDetailInner key={challengeId} challengeId={challengeId} />
    )
}

export interface ChallengeDetailProps extends RouteComponentProps {
    challengeId?: string | null
}

const ChallengeDetailInner = withRouter(class extends React.Component<ChallengeDetailProps, {
    challenge?: Challenge
    ranking?: Ranking[]
    loading: boolean
    rankingLoading: boolean
}> {

    constructor(props: ChallengeDetailProps) {
        super(props);
        this.state = {
            loading: true,
            rankingLoading: true
        }
    }

    componentDidMount() {
        this.props.challengeId ? this.getChallenge(this.props.challengeId) : this.setState({ loading: false })
    }

    getChallenge(challengeId: string) {
        ChallengesAPI.getChallenge(challengeId).then((challenge) => {
            this.setState({ challenge })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ loading: false })
        })
        ChallengesAPI.getRanking(challengeId).then((ranking) => {
            this.setState({ ranking })
        }).catch((err) => {
            /* ToDo */
        }).finally(() => {
            this.setState({ rankingLoading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        const { challenge } = this.state
        return (
            <div className="main">
                {challenge ?
                    <div className="row">
                        <div className="col-xl-8 col-md-10 col-12">
                            <div className="challenge-detail-card">
                                <h3>{challenge.name}</h3>
                                <div className="row">
                                    <div className="col-12">
                                        <span>{challenge.description}</span>
                                    </div>
                                    {/*                                     <div className="col-md-6" /> */}
                                    <div className="col-md-3 col-6">
                                        <div className="info"><span>{"Start: "}</span>{challenge.start_date || "-"}
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="info"><span>{"Ende: "}</span>{challenge.end_date || "-"}</div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="info"><span>{"Ziel: "}</span>{challenge.target || "-"}</div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="info"><span>{"Belohnung: "}</span>{challenge.prize || "-"}</div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="info"><span>{"Gesamtpunkte: "}</span>{this.state.ranking ? this.calculateTotalPoints(this.state.ranking).toString() : "-"}</div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="info"><span>{"Durschn. je Teilnehmer: "}</span>{this.state.ranking ? this.calculateTotalPoints(this.state.ranking)/this.state.ranking.length : "-"}</div>
                                    </div>
                                    <div className="col-12" style={{marginBottom: 16}}>
                                        <p>{"Ranking"}</p>
                                        {this.state.ranking ?
                                            this.state.ranking.slice().sort((a, b) => b.points - a.points).map((val, index) => {
                                                return this.renderRanking(val, index)
                                            })
                                            :
                                            !this.state.rankingLoading ?
                                                <div>{"Das Ranking kann gerade nicht geladen werden."}</div>
                                                :
                                                <ClipLoader size={20}/>
                                        }

                                    </div>
                                </div>
                                <div className="edit_delete">
                                    {!challenge.is_fixed && <Link to={`/edit-challenge?challengeId=${challenge.id}`}>
                                        <i className="fa fa-edit"></i>
                                    </Link>}
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div>{"Die Challenge kann gerade nicht geladen werden."}</div>
                }

            </div>
        )
    }

    renderRanking(ranking: Ranking, index: number) {
        return (
            <div key={index}>
                <span style={{display: 'inline-block', width: 30}}>{`${index + 1}.`}</span>
                <span style={{display: 'inline-block', width: 120}}>{ranking.name || "Anonym"}</span>
                <span style={{display: 'inline-block', width: 60, textAlign: 'right'}}>{ranking.points}</span>
            </div>
        )
    }

    calculateTotalPoints(ranking: Ranking[]) {
        console.log('this.state.ranking:', this.state.ranking);
        const totalPoints = ranking.reduce((sum, user) => {
            console.log('user.points:', user.points, typeof user.points);
            const userPoints = typeof user.points === 'string' ? parseInt(user.points, 10) : user.points;
            return sum + userPoints;
        }, 0);
        console.log('totalPoints:', totalPoints);
        return totalPoints;
    }

})

export default React.memo(ChallengeDetail)
import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { LOGO } from '../assets';
import { UserSession } from '../utils/session';


export interface SidebarProps extends RouteComponentProps {
    onNavItemPress: (item: NavItem) => void;
    userRole: 'admin' | 'manager'
}

export interface NavItem {
    link: string
    name: string
}

const ManagerNav: NavItem[] = [
    {
        link: '/dashboard',
        name: 'Dashboard'
    },
    {
        link: '/customers',
        name: 'Kunden'
    },
    {
        link: '/workout',
        name: 'Workout'
    },
    {
        link: '/meditation',
        name: 'Meditation'
    },
    {
        link: '/news',
        name: 'News'
    },
    {
        link: '/recipes',
        name: 'Rezepte'
    },
    {
        link: '/discounts',
        name: 'Rabatte'
    },
    {
        link: '/challenges',
        name: "Challenges"
    },
    {
        link: '/qr',
        name: 'Partnerübersicht'
    }
]

const AdminNav: NavItem[] = [
    {
        link: '/dashboard',
        name: 'Dashboard'
    },
    {
        link: '/company',
        name: 'Unternehmen'
    },
    {
        link: '/contributors',
        name: 'Mitarbeiter'
    },
    {
        link: '/challenges',
        name: "Challenges"
    },
    {
        link: '/company-events',
        name: "Events"
    }
]

export default withRouter(class Sidebar extends React.Component<SidebarProps, {
    hide: boolean
}> {

    constructor(props: SidebarProps) {
        super(props);
        this.state = {
            hide: true
        }
        this.setNavClass = this.setNavClass.bind(this)
    }

    private navBar: HTMLUListElement | null = null;

    componentDidMount() {
        this.setNavClass()
        window.addEventListener('resize', this.setNavClass)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setNavClass)
    }

    setNavClass() {
        if (this.navBar) {
            if (window.innerWidth < 992) {
                this.navBar.classList.add('responsive')
                this.state.hide && this.navBar.classList.add('hide')
            } else {
                this.navBar.classList.remove('responsive')
                this.navBar.classList.remove('hide')
            }
        }
    }

    render() {
        let nav: NavItem[] = this.props.userRole === 'admin' ? AdminNav : ManagerNav
        return (
            <div className="leftbar col-lg-2">
                <div className="logo">
                    <Link to='/dashboard'>
                        <img src={LOGO} alt="logo" />
                    </Link>
                </div>
                <i className="fa fa-bars menu" onClick={(e) => {
                    e.stopPropagation()
                    this.navBar && this.navBar.classList.toggle('hide')
                    this.setState({ hide: !this.state.hide })
                }}></i>
                <ul className="navigation" ref={(ref) => this.navBar = ref}>
                    {nav.map((val) => {
                        return this.renderLink(val)
                    })}
                    <li>
                        <i className="fa fa-sign-out"></i>
                        <a onClick={() => { this.logout() }}>Logout</a>
                    </li>
                </ul>
            </div>
        )
    }

    renderLink(navItem: NavItem) {
        return (
            <li key={navItem.name}>
                <Link to={navItem.link}>{navItem.name}</Link>
            </li>
        )
    }

    logout() {
        UserSession.logout()
        this.props.history.push('/login')
    }
})
import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Button from '../../components/Button';
import { LoginAPI } from '../../api/endpoints';
import toastr from 'toastr'

export interface ChangePasswordProps extends RouteComponentProps {

}

export default withRouter(class ChangePassword extends React.Component<ChangePasswordProps, {
    saveLoading: boolean,
    oldPW: string,
    newPW: string,
}> {


    constructor(props: ChangePasswordProps) {
        super(props);
        this.state = {
            saveLoading: false,
            oldPW: "",
            newPW: "",
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading}
                                className={"primary"}
                            />
                        </div>
                    </div>
                </div>
                <div className="main">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p>Altes Passwort</p>
                                        <input
                                            type="password"
                                            placeholder=""
                                            value={this.state.oldPW}
                                            onChange={(event) => {
                                                this.setState({ oldPW: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Neues Passwort</p>
                                        <input
                                            type="password"
                                            placeholder=""
                                            value={this.state.newPW}
                                            onChange={(event) => {
                                                this.setState({ newPW: event.target.value })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    onSave() {
        if (this.isValid()) {
            this.setState({ saveLoading: true })
            LoginAPI.changePassword(this.state.oldPW, this.state.newPW).then((res) => {
                toastr.success("Passwort wurde geändert")
                this.props.history.push("/profile")
            }).catch((err) => {
                toastr.error("Das Passwort konnte nicht geändert werden")
            }).finally(() => {
                this.setState({saveLoading: false})
            })
        }
    }

    isValid() {
        let valid = true
        if (!this.state.oldPW) {
            toastr.warning("Bitte gebe dein altes Passwort ein.")
            valid = false
        }
        if(!this.state.newPW) {
            toastr.warning("Bitte gebe ein neues Passwort ein.")
            valid = false
        }
        if(this.state.newPW.length < 6) {
            toastr.warning("Das Passwort muss aus mindestens 6 Zeichen bestehen.")
            valid = false
        }
        if(this.state.newPW.length > 100) {
            toastr.warning("Das Passwort darf aus maximal 100 Zeichen bestehen.")
            valid = false
        }
        return valid
    }
})
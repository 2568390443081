import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { USER_PLACEHOLDER } from '../../assets';
import Searchbar from '../../components/Searchbar';
import { Training, TrainingType } from '../../api/models/training';
import { API } from '../../api/endpoints/training';
import ClipLoader from 'react-spinners/ClipLoader';
import { getTrainingsType } from '../../utils/translate';
import { TRAINING_DROPDOWN_ITEMS, TRAINING_TYPE } from '../../utils/constants';

export interface MeditationsProps extends RouteComponentProps {

}

export default withRouter(class Meditations extends React.Component<MeditationsProps, {
    loading: boolean
    trainings?: Training[],
    count?: number,
    page: number
}> {

    constructor(props: MeditationsProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
        }
    }

    private limit: number = 10

    componentDidMount() {
        this.countTraining()
        this.getTrainings()
    }

    getTrainings() {
        API.getTrainings('meditation', undefined, this.limit, this.limit * (this.state.page - 1)).then((trainings) => {
            this.setState({ trainings, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countTraining() {
        API.countTrainings('meditation').then((count) => {
            this.setState({ count })
        }).catch((err) => { })
    }

    render() {

        return (
            <>
                <Searchbar
                    buttons={[
                        {
                            title: "Meditation anlegen",
                            onClick: () => { this.props.history.push("/add-meditation") }
                        }
                    ]}
                />
                <div className="main">
                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.trainings ?
                            this.state.trainings.length > 0 ?
                                <div className="row">
                                    {this.state.trainings.map((training) => {
                                        return this.renderMeditation(training)
                                    })}
                                </div>
                                :
                                <div>No Meditations yet</div>
                            :
                            <div>Cannot load Meditations</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.trainings) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </>
        )
    }


    renderMeditation(training: Training) {
        return (
            <div className="col-lg-12" key={`meditation_${training.id}`}>
                <div className="workoutcard">
                    <div className="row">
                        <div className="col-lg-2 col-4">
                            <img src={training.video_thumbnail} />
                        </div>
                        <div className="col-lg-5 col-8 box-align-center">
                            <span className="headline">{training.name}</span>
                        </div>
                        <div className="col-lg-3 col-6">
                            <p className="video_text">{getTrainingsType(training.type)}</p>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="edit_delete">
                                <Link to={`/meditation-detail?meditationId=${training.id}`}>
                                    <i className="fa fa-info-circle"></i>
                                </Link>
                                <Link to={`/edit-meditation?meditationId=${training.id}`}>
                                    <i className="fa fa-edit"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getTrainings()
        })
    }
})
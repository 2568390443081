import { Challenge, PointsType, Ranking, UserChallengeAssociation } from "../models/challenge";
import { APIVersion, AuthenticatedRequest, HTTPMethod } from "../requests/request";


export class API {

    /**
     * Returns the available challenges of a user
     */
    public static getChallenges(limit?: number, offset?: number): Promise<Challenge[]> {
        let apiReqest = new AuthenticatedRequest<Challenge>('/challenges', HTTPMethod.GET, APIVersion.v1_0, {
            limit,
            offset
        });
        return apiReqest.send(Challenge).then((response) => {
            return <Challenge[]>response.data
        })
    }

    /**
     * Count the available challenges of a user
     */
    public static countChallenges(): Promise<number> {
        let apiReqest = new AuthenticatedRequest<number>('/challenges/count', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Returns the challenges in which a user participates.
     */
    public static getOwnChallenges(): Promise<Challenge[]> {
        let apiReqest = new AuthenticatedRequest<Challenge>('/challenges/me', HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send(Challenge).then((response) => {
            return <Challenge[]>response.data
        })
    }

    /**
     * Returns the Ranking of a challenge
     * 
     * @param challengeId 
     */
    public static getRanking(challengeId: string): Promise<Ranking[]> {
        let apiReqest = new AuthenticatedRequest<Ranking>(`/challenges/${challengeId}/ranking`, HTTPMethod.GET, APIVersion.v2_0);
        return apiReqest.send(Ranking).then((response) => {
            return <Ranking[]>response.data
        })
    }

    /**
     * Create a Challenge Participation
     * 
     * @param challengeId 
     * @param anonym 
     * @param share_points 
     */
    public static createChallengeParticipation(challengeId: string, anonym?: boolean, share_points?: boolean): Promise<UserChallengeAssociation> {
        let apiReqest = new AuthenticatedRequest<UserChallengeAssociation>(`/challenges/participation/${challengeId}`, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            anonym,
            share_points
        });
        return apiReqest.send(UserChallengeAssociation).then((response) => {
            return <UserChallengeAssociation>response.data
        })
    }

    public static updateChallengeParticipation(userChallengeAssociationId: string, anonym?: boolean, share_points?: boolean): Promise<UserChallengeAssociation> {
        let apiReqest = new AuthenticatedRequest<UserChallengeAssociation>(`/challenges/participation/${userChallengeAssociationId}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            anonym,
            share_points
        });
        return apiReqest.send(UserChallengeAssociation).then((response) => {
            return <UserChallengeAssociation>response.data
        })
    }

    public static deleteChallengeParticipation(userChallengeAssociationId: string): Promise<any> {
        let apiReqest = new AuthenticatedRequest<any>(`/challenges/participation/${userChallengeAssociationId}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Returns a specific Challenge
     * 
     * @param id 
     */
    public static getChallenge(id: string): Promise<Challenge> {
        let apiReqest = new AuthenticatedRequest<Challenge>(`/challenges/${id}`, HTTPMethod.GET, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }

    /**
     * Create a new Challenge
     * 
     * @param name 
     * @param description 
     * @param type 
     * @param target 
     * @param prize 
     * @param start_date 
     * @param end_date 
     */
    public static addChallenge(name: string, description: string, points_type: string[], target?: number, prize?: number, start_date?: Date, end_date?: Date): Promise<Challenge> {
        let apiReqest = new AuthenticatedRequest<Challenge>('/challenges', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            name,
            description,
            points_type,
            target,
            prize,
            start_date,
            end_date,
            is_fixed: false
        });
        return apiReqest.send(Challenge).then((response) => {
            return <Challenge>response.data
        })
    }

    /**
     * Update a Challenge
     * 
     * @param name 
     * @param description 
     * @param type 
     * @param target 
     * @param prize 
     * @param start_date 
     * @param end_date 
     */
    public static updateChallenge(id: string, name?: string, description?: string, type?: string[], target?: number, prize?: number, start_date?: Date, end_date?: Date): Promise<Challenge> {
        let apiReqest = new AuthenticatedRequest<Challenge>(`/challenges/${id}`, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            name,
            description,
            type,
            target,
            prize,
            start_date,
            end_date,
            isFixed: false
        });
        return apiReqest.send(Challenge).then((response) => {
            return <Challenge>response.data
        })
    }

    /**
     * Delete a challenge
     * 
     * @param id 
     */
    public static deleteChallenge(id: string): Promise<{}> {
        let apiReqest = new AuthenticatedRequest<{}>(`/challenges/${id}`, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiReqest.send().then((response) => {
            return response.data
        })
    }
}
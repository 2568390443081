import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../api/models/user';
import { USER_PLACEHOLDER } from '../assets';

export interface HeaderProps extends RouteComponentProps {
    title: string,
    user: User
}

export default withRouter(class Header extends React.Component<HeaderProps, {}> {

    render() {

        return(
            <div className="header">
                <div className="row">
                    <div className="col-6">
                        <p>{this.props.title}</p>
                    </div>
                    <div className="col-6 rightcolumn" >
                        {/* <i className="fa fa-search"></i> */}
                        {/* <i className="fa fa-bell"></i> */}
                        <img src={this.props.user.profile_image_url || USER_PLACEHOLDER} onClick={() => {this.props.history.push("/profile")}} />
                    </div>
                </div>
            </div>
        )
    }
})


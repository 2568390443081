import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar';
import { News as INews } from '../../api/models/news';
import { API } from '../../api/endpoints/news';
import ClipLoader from 'react-spinners/ClipLoader';
import { getNewsType } from '../../utils/translate';

export interface NewsProps extends RouteComponentProps {

}

export default withRouter(class News extends React.Component<NewsProps, {
    news?: INews[]
    loading: boolean,
    page: number,
    count?: number
}> {


    constructor(props: NewsProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1
        }
    }

    private limit: number = 12

    componentDidMount() {
        this.countNews()
        this.getNews()
    }

    getNews() {
        API.getNews(undefined, undefined, this.limit, this.limit * (this.state.page - 1)).then((news) => {
            this.setState({ news, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countNews() {
        API.countNews().then((count) => {
            this.setState({count})
        }).catch((err) => {})
    }

    render() {

        return (
            <>
                <Searchbar
                    buttons={[
                        {
                            title: "News anlegen",
                            onClick: () => { this.props.history.push("/add-news") }
                        }
                    ]}
                />
                <div className="main">

                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.news ?
                            this.state.news.length > 0 ?
                                <div className="row">
                                    {this.state.news.map((news) => {
                                        return this.renderNews(news)
                                    })}
                                </div>
                                :
                                <div>No News yet</div>
                            :
                            <div>Cannot load News</div>
                    }

                </div>
                {!this.state.loading && Boolean(this.state.news) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </>
        )
    }


    renderNews(news: INews) {
        return (
            <div className="col-sm-6 col-md-4 col-xl-3" key={`news_${news.id}`}>
                <div className="newscard">
                    <h6>{news.headline}</h6>
                    <img src={news.img_url} />
                    <div className="text">
                        <p className="accentuation">{getNewsType(news.type)}</p>
                        <span>{news.text}</span>
                    </div>
                    <div className="edit_delete">
                        <Link to={`/news-detail?newsId=${news.id}`}>
                            <i className="fa fa-info-circle"></i>
                        </Link>
                        <Link to={`/edit-news?newsId=${news.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getNews()
        })
    }
})
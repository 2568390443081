import { BaseModel } from "./base";
import { JsonProperty } from "../requests/jsonparser";

export type TrainingType = 'business_standing' | 'business_sedentary_desk' | 'business_sedentary_car' | 'home_whole_body' | 'home_backfit' | 'home_mobility' | 'home_hiit' | 'home_alb' | 'meditation' | 'home_upper_body';


export class Training extends BaseModel {

    @JsonProperty({ type: 'string' })
    name: string;

    @JsonProperty({ type: 'string' })
    video_url: string;

    @JsonProperty({ type: 'string' })
    video_thumbnail?: string;

    @JsonProperty({ type: 'string' })
    type: TrainingType;

    @JsonProperty({ type: 'number' })
    points: number;
}
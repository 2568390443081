import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar';
import ClipLoader from 'react-spinners/ClipLoader';
import { User } from '../../api/models/user';
import { UserContextType } from '../../context/user';
import { MAIL_ICON } from '../../assets';

export interface ProfileProps extends RouteComponentProps {
    user: User
}

export default withRouter(class Profile extends React.Component<ProfileProps, {

}> {

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>


    constructor(props: ProfileProps) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log("Context", this.context)
    }

    render() {

        return (
            <>
                <Searchbar
                    buttons={[
                        {
                            title: "Profil ändern",
                            onClick: () => { this.props.history.push("/change-profile") }
                        },
                        {
                            title: "Passwort ändern",
                            onClick: () => { this.props.history.push("/change-password") }
                        }
                    ]}
                />
                <div className="main">
                    {/*                     <div className="row">
                        <div className="col-lg-12">
                            <div className="profile">
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <p>Name</p>
                                        <p>{this.context!.full_name}</p>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <p>Email</p>
                                        <p>{this.context!.email}</p>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <p>Profilbild</p>
                                        {this.context!.profile_image_url ? <img src={this.context!.profile_image_url} /> : <p>Noch kein Profilbild</p>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="challenge-detail-card">
                                <h3>{this.context!.full_name}</h3>
                                <div className="custom-row">
                                    {this.context!.profile_image_url && <img src={this.context!.profile_image_url} />}
                                    <div>
                                        <div className="info"><img src={MAIL_ICON} />{this.context!.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </>
        )
    }
})
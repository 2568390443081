import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/all.scss'

const FallbackRouterApp = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/:page?" component={App} />
            </Switch>
        </BrowserRouter>
    )
}

ReactDOM.render(
    <FallbackRouterApp />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {Challenge, PointsType} from './../api/models/challenge';
import {DropdownItemProps} from 'semantic-ui-react'
import {getRecipeType, getTrainingsType} from './translate';

export const USER_KEY = 'fitjo-user-session-key';

export const NEWS_TYPE = {
    GENERAL: 'general',
    OFFICE: 'office',
    TRAINING: 'training',
    NUTRITION: 'nutrition'
}

export const TRAINING_TYPE = {
    BUSINESS_STANDING: 'business_standing', 
    BUSINESS_SEDENTARY_DESK: 'business_sedentary_desk',
    BUSINESS_SEDENTARY_CAR: 'business_sedentary_car',
    HOME_WHOLE_BODY: 'home_whole_body',
    HOME_BACKFIT: 'home_backfit',
    HOME_MOBILITY: 'home_mobility',
    HOME_HIIT: 'home_hiit',
    HOME_ALB: 'home_alb',
    MEDITATION: 'meditation',
    HOME_UPPER_BODY: 'home_upper_body'
}

export const TRAINING_DROPDOWN_ITEMS: DropdownItemProps[] = [
    {
        text: getTrainingsType('business_sedentary_car'),
        value: TRAINING_TYPE.BUSINESS_SEDENTARY_CAR
    },
    {
        text: getTrainingsType('business_sedentary_desk'),
        value: TRAINING_TYPE.BUSINESS_SEDENTARY_DESK
    },
    {
        text: getTrainingsType('business_standing'),
        value: TRAINING_TYPE.BUSINESS_STANDING
    },
    {
        text: getTrainingsType('home_alb'),
        value: TRAINING_TYPE.HOME_ALB
    },
    {
        text: getTrainingsType('home_backfit'),
        value: TRAINING_TYPE.HOME_BACKFIT
    },
    {
        text: getTrainingsType('home_hiit'),
        value: TRAINING_TYPE.HOME_HIIT
    },
    {
        text: getTrainingsType('home_mobility'),
        value: TRAINING_TYPE.HOME_MOBILITY
    },
    {
        text: getTrainingsType('home_whole_body'),
        value: TRAINING_TYPE.HOME_WHOLE_BODY
    },
    {
        text: getTrainingsType('home_upper_body'),
        value: TRAINING_TYPE.HOME_UPPER_BODY
    }
]

export const RECIPE_TYPE = {
    BREAKFAST: 'breakfast',
    COLD: 'cold',
    WARM: 'warm',
    SNACK: 'snack'
}

export const RECIPE_DROPDOWN_ITEMS: DropdownItemProps[] = [
    {
        text: getRecipeType('breakfast'),
        value: RECIPE_TYPE.BREAKFAST
    },
    {
        text: getRecipeType('cold'),
        value: RECIPE_TYPE.COLD
    },
    {
        text: getRecipeType('warm'),
        value: RECIPE_TYPE.WARM
    },
    {
        text: getRecipeType('snack'),
        value: RECIPE_TYPE.SNACK
    }
]

export const POINTS_TYPE = {
    TRAINING_BUSINESS: 'training_business',
    TRAINING_HOME: 'training_home',
    MEDITATION: 'meditation',
    STEPS: 'steps',
    BIKE: 'bike',
    QR: 'qr'
}

export const POINTS_DROPDOWN_ITEMS: DropdownItemProps[] = [
    {
        text: "Training Business",
        value: POINTS_TYPE.TRAINING_BUSINESS
    },
    {
        text: "Training Home",
        value: POINTS_TYPE.TRAINING_HOME
    },
    {
        text: "Meditation",
        value: POINTS_TYPE.MEDITATION
    },
    {
        text: "Steps",
        value: POINTS_TYPE.STEPS
    },
    {
        text: "Fahrrad",
        value: POINTS_TYPE.BIKE
    },
    {
        text: "LIVE Welt",
        value: POINTS_TYPE.QR
    }
]

export const FIXED_COMPANY_CHALLENGE: Challenge = {
    name: "Firmenchallenge",
    description: "Hier sammelst Du die Punkte durch Training, Meditation und Schritte. Es ist nicht ersichtlich, wodurch Du Deine Punktezahl erreicht hast. Nach dem Absolvieren eines Trainings oder einer Meditation werden Deine Punkte automatisch zu der laufenden Challenge gezählt.",
    points_type: [PointsType.Meditation, PointsType.Steps, PointsType.TrainingBusiness, PointsType.TrainingHome, PointsType.Bike],
    is_fixed: true
}

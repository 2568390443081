import { Session } from './../api/requests/request';
import { Device } from "../api/models/device";
import { User } from "../api/models/user";
import { USER_KEY } from './constants';

export interface SessionData {
    user: User;
    device: Device;
}

let sessionUpdatedHandler: ((session: SessionData | undefined) => void) | undefined = undefined;
let userUpdatedHandler: ((user: User) => void) | undefined = undefined;

export class UserSession {

    static setSessionUpdatedHandler(handler: ((session: SessionData | undefined) => void) | undefined) {
        sessionUpdatedHandler = handler;
    }

    static getSessionUpdatedHandler() {
        return sessionUpdatedHandler || (() => { })
    }

    static setUserUpdatedHandler(handler: ((user: User) => void) | undefined) {
        userUpdatedHandler = handler;
    }

    static getUserUpdatedHandler() {
        return userUpdatedHandler || (() => { })
    }

    static logout() {
        try {
            localStorage.removeItem(USER_KEY)
        } catch (error) {

        }
        UserSession.getSessionUpdatedHandler()(undefined)
        Session.setSession(undefined)
    }
}
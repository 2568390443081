import { Options, request } from "./native_request";
import MapUtils from "./jsonparser";
import { APIErrorType } from "../errors";
import { Environment } from "../../env";

/**
 * The currenly used api url
 * @type {string}
 */
let apiURL = Environment.API

/**
 * The currenly used user agent
 * @type {string}
 */
let userAgent = 'fitjo';

/**
 * The global handler for unauthorized handlers
 */
let unauthorizedHandler: ((error: APIResult) => void) | undefined = undefined;

/**
 * This encapsulates the current session information
 */
export class Session {
    private static cachedSession: { session_token: string };

    /**
     * This sets the global unauthorized handler
     */
    static setUnauthorizedHandler(handler: ((error: APIResult) => void) | undefined) {
        unauthorizedHandler = handler;
    }

    /**
     * This returns the global unauthorized handler
     */
    static getUnauthorizedHandler(): ((error: APIResult) => void) | undefined {
        return unauthorizedHandler;
    }

    /**
     * This sets the api url to use
     * @param {string} url
     */
    static setAPIURL(url: string) {
        apiURL = url;
    }

    /**
     * This returns the currently used api url
     * @returns {string}
     */
    static getAPIURL(): string {
        return apiURL;
    }

    /**
     * This sets the user agent to use
     * @param {string} url
     */
    static setUserAgent(agent: string) {
        userAgent = agent;
    }

    /**
     * This returns the currently used user agent
     * @returns {string}
     */
    static getUserAgent(): string {
        return userAgent;
    }

    /**
     * This retreives the current session data
     * @returns {string | undefined}
     */
    static getSession(): string | undefined {
        return this.cachedSession ? this.cachedSession.session_token : undefined;
    }

    /**
     * This sets the current session data
     * @param {any | string | undefined} session
     */
    static setSession(session: any | string | undefined) {
        this.cachedSession = typeof session === 'object' ? session : { 'session_token': session }
    }
}


export type RequestType<T> = Options;
export type APIResult = {
    error: APIError
    message: string
    underlying?: Error
}

export type APIError = {
    type: APIErrorType
    status: number
    missing_keys?: string[]
    invalid_keys?: string[]
}

export enum HTTPMethod {
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    POST = "POST",
    PATCH = "PATCH"
}

export enum APIVersion {
    v1_0 = "v1",
    v2_0 = "v2"
}

export type APIResponse<T> = { data?: T | T[], meta?: { [key: string]: any }, originalResponse: Response }

export interface Request<T> {

    path: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };

    serialize(): RequestType<T>
}

export class BasicRequest<T> implements Request<T> {

    path: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };
    preventInvalidSubscriptionHandler = false

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        this.path = path;
        this.method = method;
        this.apiVersion = apiVersion;
        this.queryParameters = queryParameters;
        this.bodyParameters = bodyParameters;

    }

    serialize(): RequestType<T> {

        let apiRequest: Options = {
            url: apiURL + '/' + this.apiVersion + this.path,
            json: true,
            method: this.method,
            headers: {},
            user_agent: userAgent
        };
        if (this.queryParameters) {
            apiRequest.qs = this.queryParameters
        }
        if (this.bodyParameters) {
            apiRequest.body = this.bodyParameters;
        }
        return apiRequest
    }

    public send(returnType?: { new(): T }): Promise<APIResponse<any>> {
        let promise = new Promise((resolve, reject: (reason?: any) => void) => {
            request(this.serialize(), (error?: Error, response?: Response, body?: any) => {
                if (error) {
                    reject(error);
                } else if (response && response.status >= 200 && response.status <= 299) {
                    if (body) {
                        if (!returnType || true) { // for now always disable parsing
                            return resolve({
                                data: body.data,
                                originalResponse: response
                            })
                        }
                    } else if (body && body.error) {
                        reject(body.error)
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed 1'
                        })
                    }
                } else {
                    if (body && body.error) {
                        reject(body.error)
                        return
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed 2'
                        })
                    }
                }
            })
        });
        return <Promise<APIResponse<T>>>promise.catch((error) => {
            if (error && error.type && error.status) {
                throw error
            } else {
                const error: any = new Error('message');
                error.type = APIErrorType.NetworkError;
                error.status = 999;
                error.message = 'An unknown error occurred / ' + this.path + ' / ' + this.method;
                error.underlying = error;
                throw error
            }
        });
    }

    private parseReponse(clazz: { new(): T }, response: any): T | undefined {
        return MapUtils.deserialize(clazz, response)
    }

}

export class AuthenticatedRequest<T> extends BasicRequest<T> {

    private refreshToken?: string;
    private authToken?: string;

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any },
        refresh_token?: string) {
        super(path, method, apiVersion, queryParameters, bodyParameters);
        this.refreshToken = refresh_token
        this.authToken = Session.getSession();
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        if (this.authToken) {
            options.headers = Object.assign({}, options.headers, { 'Authorization': `Bearer ${this.authToken}` });
        } else if (this.refreshToken) {
            options.headers = Object.assign({}, options.headers, { 'Authorization': `Bearer ${this.refreshToken}` });
        }

        return options
    }
}

export class AuthenticatedFileRequest<T> extends AuthenticatedRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: string },
        bodyParameters?: { [key: string]: any }) {
        if (bodyParameters) {
            let body = new FormData();
            let data = bodyParameters;
            for (let key of Object.keys(data)) {
                body.append(key, data[key], 'image');
            }
            bodyParameters = body;
            //bodyParameters = body["_parts"];
            /*                 let body = new Array<any>()
                            let data = bodyParameters;
                            body.push({name: 'image', filename: data.image.name, data: data.image.exif})
                            Object.keys(data).filter(key => (key !== 'image')).forEach(key => {
                              const item = { name: key, data: data[key] }
                              if (item.data && typeof item.data !== 'function') {
                                body.push(item)
                              }
                            })
                            bodyParameters = body */
            //bodyParameters.image = bodyParameters.image.exif
        }
        super(path, method, apiVersion, queryParameters, bodyParameters);
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        options.json = false;
        return options
    }
}
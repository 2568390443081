import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { PLAY_BTN, UPLOAD_IMAGE } from '../../assets';
import { NEWS_TYPE } from '../../utils/constants';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { NewsType } from '../../api/models/news';
import { API } from '../../api/endpoints/news';
import { News as INews } from '../../api/models/news'
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'

export const AddNews: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let newsId = query.get("newsId")
    return (
        <AddNewsInner key={newsId} newsId={newsId} />
    )
}

export interface AddNewsProps extends RouteComponentProps {
    newsId?: string | null
}

const AddNewsInner = withRouter(class extends React.Component<AddNewsProps, {
    title: string
    text: string
    type: NewsType,
    img_url?: string
    file?: File | null,
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
}> {

    constructor(props: AddNewsProps) {
        super(props);
        this.state = {
            type: NEWS_TYPE.GENERAL as NewsType,
            loading: true,
            title: "",
            text: "",
            saveLoading: false,
            deleteLoading: false
        }
        this.selectImage = this.selectImage.bind(this)
    }
    private fileInput: HTMLInputElement | null = null

    componentDidMount() {
        this.props.newsId ? this.getNewsArticle(this.props.newsId) : this.setState({ loading: false })
    }

    getNewsArticle(newsId: string) {
        API.getNewsArticle(newsId).then((article) => {
            this.setState({ title: article.headline, text: article.text, type: article.type, img_url: article.img_url })
        }).catch((err) => {
            toastr.error("Der Artikel konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.fileInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.newsId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"News löschen"}
                                    onClick={() => {this.deleteArticle()}}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="news_add_card">
                                <div className="row">

                                    <div className="col-sm-6">
                                        <p>Titel</p>
                                        <input
                                            placeholder="News Titel"
                                            value={this.state.title}
                                            onChange={(event) => {
                                                this.setState({ title: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <p>Kategorie</p>
                                        <Dropdown
                                            value={this.state.type}
                                            fluid
                                            selection
                                            options={[
                                                {
                                                    text: "Allgemein",
                                                    value: NEWS_TYPE.GENERAL
                                                },
                                                {
                                                    text: "Office",
                                                    value: NEWS_TYPE.OFFICE
                                                },
                                                {
                                                    text: "Training",
                                                    value: NEWS_TYPE.TRAINING
                                                },
                                                {
                                                    text: "Ernährung",
                                                    value: NEWS_TYPE.NUTRITION
                                                },
                                            ]}
                                            onChange={(_, { value }) => {
                                                this.setState({ type: value as NewsType })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Titelbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.fileInput && this.fileInput.click()
                                        }} />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                    <div className="col-12">
                                        <p>Text</p>
                                        <textarea
                                            rows={20}
                                            placeholder="News Text"
                                            value={this.state.text}
                                            onChange={(event) => {
                                                this.setState({ text: event.target.value })
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ file, img_url: url })
            }
        }
    }

    onSave() {
        if (this.state.title && this.state.text) {
            this.setState({ saveLoading: true })
            if (this.state.file) {
                API.uploadNewsImage(this.state.file).then((url) => {
                    this.addArticle(this.state.title, this.state.text, this.state.type, url)
                }).catch((err) => {
                    toastr.warning("Bild konnte nicht hochgeladen werden")
                    this.addArticle(this.state.title, this.state.text, this.state.type)
                })
            } else {
                this.addArticle(this.state.title, this.state.text, this.state.type)
            }
        }
    }

    addArticle(headline: string, text: string, type: NewsType, img_url?: string) {
        if (this.props.newsId) {
            API.updateNewsArticle(this.props.newsId, headline, text, type, img_url).then((article) => {
                toastr.success("News Artikel wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("News Artikel konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            API.addNewsArticle(headline, text, type, img_url).then((article) => {
                toastr.success("News Artikel wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("News Artikel konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }
    }

    deleteArticle() {
        this.setState({deleteLoading: true})
        this.props.newsId && API.deleteNewsArticle(this.props.newsId).then(() => {
            toastr.success("News Artikel wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("News Artikel konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({deleteLoading: false})
        })
    }

    goBack() {
        this.props.history.push("/news")
    }

})

export default React.memo(AddNews)
import * as React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

interface ButtonProps {
    text: string
    className?: string
    loading: boolean
    disabled: boolean
    onClick: () => void
}

export default class Button extends React.Component<ButtonProps, {

}> {

    constructor(props: ButtonProps) {
        super(props);
        this.state = {

        }
    }


    render() {
        const { text, className, loading, disabled, onClick } = this.props
        return (
            <div className={`fitjo-button ${className || ''}`} onClick={() => !disabled && onClick()}>
                {loading ?
                    <ClipLoader size={20}
                        color={'#fff'}
                    />
                    :
                    <span>{text}</span>
                }
            </div>
        )
    }
}
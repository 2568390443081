import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { Company } from '../../api/models/company';
import { USER_PLACEHOLDER } from '../../assets';
import Searchbar from '../../components/Searchbar';
import ClipLoader from 'react-spinners/ClipLoader';
import { API } from '../../api/endpoints/company';

export interface CustomersProps extends RouteComponentProps {

}

export default withRouter(class Customers extends React.Component<CustomersProps, {
    loading: boolean
    companies?: Company[],
    page: number,
    count?: number
}> {

    constructor(props: CustomersProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1
        }
    }

    private limit: number = 12

    componentDidMount() {
        this.countCompanies()
        this.getCompanies()
    }

    getCompanies() {
        API.getCompanies(this.limit, this.limit * (this.state.page - 1)).then((companies) => {
            this.setState({ companies, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countCompanies() {
        API.countCompanies().then((count) => {
            this.setState({count})
        }).catch((err) => {})
    }

    render() {

        return (
            <div>
                <Searchbar
                    buttons={[
                        {
                            title: "Kunden anlegen",
                            onClick: () => { this.props.history.push("/add-customer") }
                        }
                    ]}
                />
                <div className="main">
                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.companies ?
                            this.state.companies.length > 0 ?
                                <div className="row">
                                    {this.state.companies.map((company) => {
                                        return this.renderCustomer(company)
                                    })}
                                </div>
                                :
                                <div>No Companies yet</div>
                            :
                            <div>Cannot load Companies</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.companies) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </div>
        )
    }

    renderCustomer(company: Company) {
        return (
            <div className="col-xl-4 col-md-6" key={`company_${company.id}`}>
                <div className="usercard">
                    {company.img_url ? <img src={company.img_url} /> : <div className="img-placeholder" />}
                    <div className="userlines">
                        <p>{company.name}</p>
                        <p>{company.email}</p>
                        <p>{company.phone_number}</p>
                    </div>
                    <div className="edit_delete">
                        {/* Mitarbeiter von Kunden anlegen? */}
                        {/* <a href="kunden_user.html"><i className="fa fa-users"></i></a> */}
                        {/* <a href="kunden_chart.html"><i className="fa fa-pie-chart"></i></a> */}
                        <Link to={`/customer-detail?customerId=${company.id}`}>
                            <i className="fa fa-info-circle"></i>
                        </Link>
                        <Link to={`/edit-customer?customerId=${company.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getCompanies()
        })
    }
})
import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Searchbar from '../../components/Searchbar';
import ClipLoader from 'react-spinners/ClipLoader';
import { Challenge } from '../../api/models/challenge';
import { ChallengesAPI } from '../../api/endpoints';

export interface ChallengesProps extends RouteComponentProps {

}

export default withRouter(class Challenges extends React.Component<ChallengesProps, {
    loading: boolean
    challenges?: Challenge[],
    page: number,
    count?: number
}> {

    constructor(props: ChallengesProps) {
        super(props);
        this.state = {
            loading: true,
            page: 1
        }
    }

    private limit: number = 12

    componentDidMount() {
        this.getChallenges()
    }

    getChallenges() {
        ChallengesAPI.getChallenges().then((challenges) => {
            this.setState({ challenges, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    countChallenges() {
        ChallengesAPI.countChallenges().then((count) => {
            this.setState({count})
        }).catch((err) => {})
    }

    render() {

        return (
            <div>
                <Searchbar
                    buttons={[
                        {
                            title: "Challenge anlegen",
                            onClick: () => { this.props.history.push("/add-challenge") }
                        }
                    ]}
                />
                <div className="main">
                    {this.state.loading ?
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            <ClipLoader />
                        </div>
                        :
                        this.state.challenges ?
                            this.state.challenges.length > 0 ?
                                <div className="row">
                                    {this.state.challenges.map((challenge) => {
                                        return this.renderChallenge(challenge)
                                    })}
                                </div>
                                :
                                <div>No Challenges yet</div>
                            :
                            <div>Cannot load Challenges</div>
                    }
                </div>
                {!this.state.loading && Boolean(this.state.challenges) && this.state.count && this.state.count > this.limit && <div className="paging">
                    {this.renderPages()}
                </div>}
            </div>
        )
    }

    renderChallenge(challenge: Challenge) {
        return (
            <div className="col-lg-3 col-md-4 col-6" key={`challenge_${challenge.id}`}>
                <div className="rezeptcard">
                    <h6>{challenge.name}</h6>
                    <div className="text">
                        {/*                         <p>Natalie M. am 29.07.2021</p> */}
                        <p>{challenge.description}</p>
                    </div>
                    <div className="edit_delete">
                        <Link to={`/challenge-detail?challengeId=${challenge.id}`}>
                            <i className="fa fa-info-circle"></i>
                        </Link>
                        {!challenge.is_fixed && <Link to={`/edit-challenge?challengeId=${challenge.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>}
                    </div>
                </div>
            </div>
        )
    }

    renderPages() {
        if (this.state.count) {
            return (
                <>
                    {this.state.page > 2 && <i className="fa fa-angle-double-left" onClick={() => this.changePage(1)}></i>}
                    {this.state.page > 1 && <i className="fa fa-angle-left" onClick={() => this.changePage(this.state.page - 1)}></i>}
                    {this.state.page - 1 > 0 && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</span>}
                    <span className="active">{this.state.page}</span>
                    {this.state.page + 1 <= Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</span>}
                    {this.state.page + 2 < Math.ceil(this.state.count / this.limit) && <span>{'...'}</span>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <span style={{ cursor: 'pointer' }} onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}>{Math.ceil(this.state.count / this.limit)}</span>}
                    {this.state.page < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-right" onClick={() => this.changePage(this.state.page + 1)}></i>}
                    {this.state.page + 1 < Math.ceil(this.state.count / this.limit) && <i className="fa fa-angle-double-right" onClick={() => this.changePage(Math.ceil(this.state.count! / this.limit))}></i>}
                </>
            )
        }
        return
    }

    changePage(page: number) {
        this.setState({page, loading: true}, () => {
            this.getChallenges()
        })
    }
})
import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { UPLOAD_IMAGE } from '../../assets';
import { TRAINING_TYPE } from '../../utils/constants';
import 'semantic-ui-css/semantic.min.css'
import { API } from '../../api/endpoints/training';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'
import { TrainingType } from '../../api/models/training';

export const AddMeditation: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let meditationId = query.get("meditationId")
    return (
        <AddMeditationInner key={meditationId} meditationId={meditationId} />
    )
}

export interface AddMeditationProps extends RouteComponentProps {
    meditationId?: string | null
}

const AddMeditationInner = withRouter(class extends React.Component<AddMeditationProps, {
    title: string
    type: TrainingType,
    points: string
    audio_file?: File | null,
    img_file?: File | null
    audio_url?: string,
    img_url?: string
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
}> {

    constructor(props: AddMeditationProps) {
        super(props);
        this.state = {
            type: TRAINING_TYPE.MEDITATION as TrainingType,
            loading: true,
            title: "",
            saveLoading: false,
            deleteLoading: false,
            points: ""
        }
        this.selectImage = this.selectImage.bind(this)
        this.selectAudio = this.selectAudio.bind(this)
    }
    private audioInput: HTMLInputElement | null = null
    private imgInput: HTMLInputElement | null = null
    private video: HTMLVideoElement | null = null

    componentDidMount() {
        this.props.meditationId ? this.getTraining(this.props.meditationId) : this.setState({ loading: false })
    }

    getTraining(trainingId: string) {
        API.getTraining(trainingId).then((training) => {
            this.setState({ title: training.name, type: training.type, img_url: training.video_thumbnail, audio_url: training.video_url, points: training.points.toString() })
        }).catch((err) => {
            toastr.error("Die Meditation konnte nicht geladen werden.")
            this.goBack()
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectAudio}
                    accept="audio/*"
                    ref={ele => (this.audioInput = ele)}
                />
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.selectImage}
                    accept="image/*"
                    ref={ele => (this.imgInput = ele)}
                />
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.props.meditationId &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Meditation löschen"}
                                    onClick={() => { this.deleteMeditation() }}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="workout_add_card">
                                <div className="row">

                                    <div className="col-md-6">
                                        <p>Titel</p>
                                        <input
                                            placeholder="Meditation Titel"
                                            value={this.state.title}
                                            onChange={(event) => {
                                                this.setState({ title: event.target.value })
                                            }}
                                        />
                                    </div>

{/*                                     <div className="col-xl-5 col-md-4 col-9">
                                        <p>Kategorie</p>
                                        <Dropdown
                                            value={this.state.type}
                                            fluid
                                            selection
                                            options={TRAINING_DROPDOWN_ITEMS}
                                            onChange={(_, { value }) => {
                                                this.setState({ type: value as TrainingType })
                                            }}
                                        />
                                    </div> */}

                                    <div className="col-xl-1 col-md-2 col-3">
                                        <p>Punkte</p>
                                        <input
                                            placeholder="50"
                                            value={this.state.points}
                                            onChange={(event) => {
                                                this.setState({points: event.target.value})
                                            }}
                                        />
                                    </div>

                                    <div className="col-xl-5 col-md-4 col-9"></div>

                                    <div className="col-lg-3 col-6">
                                        <p>Audio hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.audioInput && this.audioInput.click()
                                        }} />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Vorschau</p>
                                        <video ref={(ref) => this.video = ref} controls poster={this.state.img_url} src={this.state.audio_url} />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Titelbild hinzufügen</p>
                                        <img className="add" src={UPLOAD_IMAGE} onClick={() => {
                                            this.imgInput && this.imgInput.click()
                                        }} />
                                    </div>

                                    <div className="col-lg-3 col-6">
                                        <p>Vorschau</p>
                                        {this.state.img_url && <img src={this.state.img_url} />}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }


    selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.setState({ img_file: file, img_url: url })
            }
        }
    }

    selectAudio(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files: FileList | null = e.target.files
        if (files) {
            const file = files.item(0);
            if (file) {
                const url = URL.createObjectURL(file)
                this.video && (this.video.src = url)
                this.setState({ audio_file: file, audio_url: url })
            }
        }
    }

    onSave() {
        if (this.isValid()) {
            this.setState({ saveLoading: true })
            if (this.state.audio_file) {
                API.uploadTrainingAudio(this.state.audio_file).then((res) => {
                    if (this.state.img_file) {
                        API.uploadTrainingImage(this.state.img_file).then((img_url) => {
                            this.addMeditation(this.state.title, res.m4a, this.state.type, Number(this.state.points!), img_url)
                        }).catch((err) => {
                            toastr.warning("Bild konnte nicht hochgeladen werden")
                            this.addMeditation(this.state.title, res.m4a, this.state.type, Number(this.state.points!))
                        })
                    } else {
                        this.addMeditation(this.state.title, res.m4a, this.state.type, Number(this.state.points!))
                    }
                }).catch((err) => {
                    toastr.error("Audio konnte nicht hochgeladen werden")
                    this.setState({ saveLoading: false })
                })
            } else {
                if (this.state.img_file) {
                    API.uploadTrainingImage(this.state.img_file).then((img_url) => {
                        this.addMeditation(this.state.title, undefined, this.state.type, Number(this.state.points!), img_url)
                    }).catch((err) => {
                        toastr.warning("Bild konnte nicht hochgeladen werden")
                        this.addMeditation(this.state.title, undefined, this.state.type, Number(this.state.points!))
                    })
                } else {
                    this.addMeditation(this.state.title, undefined, this.state.type, Number(this.state.points!))
                }
            }
        }
    }

    isValid() {
        let valid = true
        if(!this.state.title) {
            toastr.warning("Bitte gebe einen Titel an")
            valid = false
        }
        if(!(this.state.audio_file || this.state.audio_url)) {
            toastr.warning("Bitte wähle ein Audio aus")
            valid = false
        }
        if(!this.state.points) {
            toastr.warning("Bitte gebe eine Punktzahl an")
            valid = false
        }
        if(this.state.points && isNaN(Number(this.state.points))) {
            toastr.warning("Bitte gebe eine gültige Punktzahl an")
            valid = false
        }
        return valid
    }

    addMeditation(name?: string, audio_url?: string, type?: TrainingType, points?: number, video_thumbnail?: string) {
        if (this.props.meditationId) {
            API.updateTraining(this.props.meditationId, name, audio_url, type, points, video_thumbnail).then((training) => {
                toastr.success("Meditation wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Meditation konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            API.addTraining(name!, audio_url!, type!, points!, video_thumbnail).then((training) => {
                toastr.success("Meditation wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Meditation konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }
    }

    deleteMeditation() {
        this.setState({ deleteLoading: true })
        this.props.meditationId && API.deleteTraining(this.props.meditationId).then(() => {
            toastr.success("Meditation wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Meditation konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({ deleteLoading: false })
        })
    }

    goBack() {
        this.props.history.push("/meditation")
    }

})

export default React.memo(AddMeditation)
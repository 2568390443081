import * as React from 'react'
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { POINTS_DROPDOWN_ITEMS } from '../../utils/constants';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button';
import toastr from 'toastr'
import { Challenge, PointsType } from '../../api/models/challenge';
import { ChallengesAPI } from '../../api/endpoints';
import moment from 'moment'

export const AddChallenge: React.FC = () => {
    let query = new URLSearchParams(useLocation().search)
    let challengeId = query.get("challengeId")
    return (
        <AddChallengeInner key={challengeId} challengeId={challengeId} />
    )
}

export interface AddChallengeProps extends RouteComponentProps {
    challengeId?: string | null
}

const AddChallengeInner = withRouter(class extends React.Component<AddChallengeProps, {
    challenge?: Challenge
    title: string
    points_type: string[],
    description: string
    target: string
    startDate: string
    endDate: string
    prize: string
    loading: boolean
    saveLoading: boolean
    deleteLoading: boolean
}> {

    constructor(props: AddChallengeProps) {
        super(props);
        this.state = {
            points_type: [],
            loading: true,
            title: "",
            saveLoading: false,
            deleteLoading: false,
            description: "",
            target: "",
            prize: "",
            startDate: "",
            endDate: ""
        }
    }

    componentDidMount() {
        this.props.challengeId ? this.getChallenge(this.props.challengeId) : this.setState({ loading: false })
    }

    getChallenge(challengeId: string) {
        ChallengesAPI.getChallenge(challengeId).then((challenge) => {
            this.setState({
                challenge,
                title: challenge.name,
                points_type: challenge.points_type,
                description: challenge.description,
                target: challenge.target?.toString() || "",
                prize: challenge.prize?.toString() || "",
                startDate: moment(challenge.start_date).format('YYYY-MM-DD'),
                endDate: moment(challenge.end_date).format('YYYY-MM-DD')
            })
        }).catch((err) => {
            /* ToDo */
            console.log('Cant load challenge', err)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader />
            </div>
        }
        return (
            <>
                <div className="create">
                    <div className="row">
                        <div className="col-lg-2 col-6">
                            <Button
                                text={"Speichern"}
                                onClick={() => this.onSave()}
                                loading={this.state.saveLoading}
                                disabled={this.state.saveLoading || this.state.deleteLoading}
                                className={"primary"}
                            />
                        </div>
                        {this.state.challenge && !this.state.challenge.is_fixed &&
                            <div className="col-lg-2 col-6">
                                <Button
                                    text={"Challenge löschen"}
                                    onClick={() => { this.deleteChallenge() }}
                                    loading={this.state.deleteLoading}
                                    disabled={this.state.saveLoading || this.state.deleteLoading}
                                    className={"danger"}
                                />
                            </div>
                        }

                    </div>
                </div>
                <div className="main">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="workout_add_card">
                                <div className="row">

                                    <div className="col-md-6">
                                        <p>Titel</p>
                                        <input
                                            placeholder="Challenge Titel"
                                            value={this.state.title}
                                            onChange={(event) => {
                                                this.setState({ title: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Ziel *</p>
                                        <input
                                            placeholder="500"
                                            value={this.state.target}
                                            onChange={(event) => {
                                                this.setState({ target: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Belohnung *</p>
                                        <input
                                            placeholder="200"
                                            value={this.state.prize}
                                            onChange={(event) => {
                                                this.setState({ prize: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Start *</p>
                                        <input
                                            placeholder="TT.MM.JJJJ"
                                            type="date"
                                            defaultValue={this.state.startDate}
                                            value={this.state.startDate}
                                            onChange={(event) => {
                                                //this.setState({target: event.target.value})
                                                console.log('StartDate', event.target.value)
                                                this.setState({ startDate: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-3 col-6">
                                        <p>Ende *</p>
                                        <input
                                            placeholder="TT.MM.JJJJ"
                                            type="date"
                                            value={this.state.endDate}
                                            onChange={(event) => {
                                                this.setState({ endDate: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-lg-6" />

                                    <div className="col-md-6 col-12">
                                        <p>Beschreibung</p>
                                        <textarea
                                            rows={5}
                                            placeholder="Beschreibung"
                                            value={this.state.description}
                                            onChange={(event) => {
                                                this.setState({ description: event.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-6 col-12">
                                        <p>Kategorie</p>
                                        <Dropdown
                                            defaultValue={this.state.points_type}
                                            //value={this.state.points_type}
                                            fluid
                                            selection
                                            multiple
                                            options={POINTS_DROPDOWN_ITEMS}
                                            onChange={(_, { value }) => {
                                                this.setState({ points_type: (value as string[]) })
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    onSave() {
        if (this.isValid()) {
            this.setState({ saveLoading: true })
            this.addChallenge()
        }
    }

    isValid() {
        let valid = true
        if (!this.state.title) {
            toastr.warning("Bitte gebe einen Titel an")
            valid = false
        }
        if (!this.state.description) {
            toastr.warning("Bitte gebe eine Beschreibung an")
            valid = false
        }
        if (this.state.points_type.length == 0) {
            toastr.warning("Bitte wähle mindestens einen Punkte Typ aus")
            valid = false
        }
        if (this.state.target && isNaN(Number(this.state.target))) {
            toastr.warning("Bitte gebe ein gültiges Ziel an")
            valid = false
        }
        if (this.state.prize && isNaN(Number(this.state.prize))) {
            toastr.warning("Bitte gebe eine gültige Belohnung an")
            valid = false
        }
        return valid
    }

    addChallenge() {
        let target = this.state.target ? Number(this.state.target) : undefined
        let prize = this.state.prize ? Number(this.state.prize) : undefined
        let start = this.state.startDate ? new Date(this.state.startDate) : undefined
        let end = this.state.endDate ? new Date(this.state.endDate) : undefined
        if (this.props.challengeId) {
            ChallengesAPI.updateChallenge(this.props.challengeId, this.state.title, this.state.description, this.state.points_type, target, prize, start, end).then((challenge) => {
                toastr.success("Challenge wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Challenge konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        } else {
            ChallengesAPI.addChallenge(this.state.title, this.state.description, this.state.points_type, target, prize, start, end).then((challenge) => {
                toastr.success("Challenge wurde gespeichert")
                this.goBack()
            }).catch((err) => {
                toastr.error("Challenge konnte nicht gespeichert werden")
            }).finally(() => {
                this.setState({ saveLoading: false })
            })
        }
    }

    deleteChallenge() {
        this.setState({ deleteLoading: true })
        this.props.challengeId && ChallengesAPI.deleteChallenge(this.props.challengeId).then(() => {
            toastr.success("Challenge wurde gelöscht")
            this.goBack()
        }).catch((err) => {
            toastr.error("Challenge konnte nicht gelöscht werden")
        }).finally(() => {
            this.setState({ deleteLoading: false })
        })
    }

    goBack() {
        this.props.history.push("/challenges")
    }

})

export default React.memo(AddChallenge)